import client from 'client'
import * as api from 'api'
import { CREDITCARD_IMGES, paymentSourceToImg } from './payment.helpers'
import { getPrice, formatPrice } from 'utils/currency'
import get from 'lodash/get'

const FETCH_METHODS = 'unicorn/payment/FETCH_METHODS'
const FETCH_TOPUP_PLANS = 'unicorn/payment/FETCH_TOPUP_PLANS'
const UPDATE_PAYMENT = 'unicorn/payment/UPDATE_PAYMENT'
const ADD_PAYMENT_SOURCE = 'unicorn/payment/ADD_PAYMENT_SOURCE'
const REMOVE_PAYMENT_SOURCE = 'unicorn/payment/REMOVE_PAYMENT_SOURCE'
const FETCH_PAYMENT_SOURCES = 'unicorn/payment/FETCH_PAYMENT_SOURCES'
const FETCH_PAYMENT_STATUS = 'unicorn/payment/FETCH_PAYMENT_STATUS'
const FETCH_PAYMENT_CONFIG = 'unicorn/payment/FETCH_PAYMENT_CONFIG'
const SET_SOURCES = 'unicorn/payment/SET_SOURCES'
const SET_METHOD = 'unicorn/payment/SET_METHOD'
const SET_BALANCE = 'unicorn/payment/SET_BALANCE'
const SET_PLAN_ID = 'unicorn/payment/SET_PLAN_ID'

export const METHODS = {
  PAYG: 'payg',
  MANUAL_TOP_UP: 'manual-top-up',
  AUTO_TOP_UP: 'auto-top-up',
}

export const fetchMethods = () => ({
  type: FETCH_METHODS,
  payload: client()
    .get('/customers/me/payments/methods')
    .then(data => data.data.methods),
})

export const fetchPaymentSources = () => ({
  type: FETCH_PAYMENT_SOURCES,
  payload: client()
    .get('/customers/me/payments/sources')
    .then(data => data.data.paymentSources),
})

export const fetchTopupPlans = () => ({
  type: FETCH_TOPUP_PLANS,
  payload: client()
    .get('/customers/me/payments/plans')
    .then(data => data.data.plans),
})

export const fetchPaymentStatus = tId => ({
  type: FETCH_PAYMENT_STATUS,
  payload: client()
    .get(`/customers/me/payments/status/${tId}`)
    .then(data => data.data.status),
})

export const fetchPaymentConfig = () => ({
  type: FETCH_PAYMENT_CONFIG,
  payload: client()
    .get(`/config/payment`)
    .then(data => data.data),
})

const updatePaymentRequest = params => {
  return client()
    .get('/nonce')
    .then(data => {
      const nonce = data.data.nonces[0]
      return client().put('/customers/me/payments', {
        nonce,
        ...params,
      })
    })
}

export const updatePayment = params => ({
  type: UPDATE_PAYMENT,
  payload: updatePaymentRequest(params),
})

export const updatePaymentSource = params => dispatch => {
  return updatePaymentRequest(params).then(() =>
    dispatch(fetchPaymentSources()),
  )
}

export const addPaymentSource = details => dispatch =>
  api
    .addPaymentSource({ details, type: 'stripe', activated: 'true' })
    .then(resp =>
      dispatch({
        type: `${ADD_PAYMENT_SOURCE}_FULFILLED`,
        payload: resp.data.paymentSource,
      }),
    )
    .then(() => dispatch(fetchPaymentSources()))

export const removePaymentSource = id => dispatch => {
  return client()
    .delete(`/customers/me/payments/sources/${id}`)
    .then(() =>
      dispatch({ type: `${REMOVE_PAYMENT_SOURCE}_FULFILLED`, payload: id }),
    )
    .then(() => dispatch(fetchPaymentSources()))
}

export const redeemCode = voucherCode => _ => {
  return client().put('/customers/me', {
    referredBy: voucherCode,
  })
}

export const setPaymentSources = payload => ({ type: SET_SOURCES, payload })
export const setPaymentMethod = payload => ({ type: SET_METHOD, payload })
export const setBalance = payload => ({ type: SET_BALANCE, payload })
export const setTopupPlanId = payload => ({ type: SET_PLAN_ID, payload })

const initialState = {
  isLoadingPlans: false,
  isLoading: false,
  isLoaded: false,
  isChanging: false,
  autoTopupPlanId: null,
  selectedMethod: null,
  methods: [],
  sources: [],
  plans: [],
}

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case `${FETCH_METHODS}_FULFILLED`:
      return Object.assign({}, state, { methods: action.payload })

    case `${FETCH_PAYMENT_SOURCES}_PENDING`:
      return Object.assign({}, state, {
        isLoading: true,
      })
    case `${FETCH_PAYMENT_SOURCES}_FULFILLED`:
      return Object.assign({}, state, {
        isLoading: false,
        isLoaded: true,
        sources: action.payload,
      })
    case `${FETCH_PAYMENT_SOURCES}_REJECTED`:
      return Object.assign({}, state, {
        isLoading: false,
      })

    case `${FETCH_TOPUP_PLANS}_PENDING`:
      return Object.assign({}, state, {
        isLoadingPlans: true,
      })
    case `${FETCH_TOPUP_PLANS}_FULFILLED`:
      return Object.assign({}, state, {
        isLoadingPlans: false,
        plans: action.payload,
      })
    case `${FETCH_TOPUP_PLANS}_REJECTED`:
      return Object.assign({}, state, {
        isLoadingPlans: false,
      })

    case `${UPDATE_PAYMENT}_PENDING`:
      return Object.assign({}, state, {
        isChanging: true,
      })
    case `${UPDATE_PAYMENT}_FULFILLED`:
      return Object.assign({}, state, {
        isChanging: false,
      })
    case `${UPDATE_PAYMENT}_REJECTED`:
      return Object.assign({}, state, {
        isChanging: false,
      })

    case `${ADD_PAYMENT_SOURCE}_FULFILLED`:
      return { ...state, sources: [...state.sources, action.payload] }

    case `${REMOVE_PAYMENT_SOURCE}_FULFILLED`:
      return {
        ...state,
        sources: state.sources.filter(x => x.id !== action.payload),
      }

    case SET_METHOD:
      return { ...state, selectedMethod: action.payload }

    case SET_SOURCES:
      return { ...state, sources: action.payload, isLoaded: true }

    case SET_BALANCE:
      return { ...state, balance: action.payload }

    case SET_PLAN_ID:
      return { ...state, autoTopupPlanId: action.payload }

    default:
      return state
  }
}

export const selectAutotopupPlan = state => state.payment.autoTopupPlanId
export const selectPaymentBalance = state => state.payment.balance
export const selectPaymentMethod = state => state.payment.selectedMethod
export const selectPaymentSources = state => state.payment.sources || []
export const selectPaymentSourcesIsLoaded = state => state.payment.isLoaded
export const selectPaymentSourcesIsLoading = state => state.payment.isLoading
export const selectPaymentSourcesIsChanging = state => state.payment.isChanging
export const selectTopupPlans = state => state.payment.plans || []

export const selectHasSufficientPaymentSource = (state, priceToPay) => {
  const { card, credit } = selectPreferredPaymentSource(state, priceToPay)
  return get(credit, 'isSufficient', false) || get(card, 'isSufficient', false)
}

export const selectPreferredPaymentSource = (state, priceToPay) => {
  const sources = {
    credit: null,
    card: null,
  }
  const method = selectPaymentMethod(state)
  const balance = selectPaymentBalance(state)
  const activeSource = selectPaymentSources(state).find(x => x.activated)

  const availableBalance =
    balance && balance.available && getPrice(balance.available)
  const isHYCreditSufficient =
    availableBalance && availableBalance >= priceToPay

  if (method === METHODS.PAYG && activeSource && !isHYCreditSufficient) {
    sources.card = {
      isSufficient: true,
      type: method,
      img: paymentSourceToImg(activeSource),
      ...activeSource,
    }
  }

  if (availableBalance) {
    sources.credit = {
      ...balance,
      isSufficient: isHYCreditSufficient,
      type: method,
      img: CREDITCARD_IMGES.GENERIC,
      description: 'Hey You Credit',
      available: formatPrice(availableBalance),
    }
  }

  return sources
}

export default reducer
