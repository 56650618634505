import asset from 'utils/asset'

export const CREDITCARD_IMGES = {
  GENERIC: asset('images/creditcards/v2/generic.png'),
  AMEX: asset('images/creditcards/v2/amex.png'),
  MASTERCARD: asset('images/creditcards/v2/mastercard.png'),
  VISA: asset('images/creditcards/v2/visa.png'),
  VISA_CHECKOUT: asset('images/creditcards/v2/visacheckout.png'),
  PAYPAL: asset('images/creditcards/v2/paypal.png'),
  DINERS: asset('images/creditcards/v2/diners.png'),
  ZIP: asset('images/creditcards/v2/zip.png'),
}

const CREDITCARD_TYPES = [
  'visa_checkout',
  'visa',
  'paypal',
  'amex',
  'mastercard',
  'diners',
  'zip',
]

export const paymentSourceToImg = payment => {
  if (!payment) return

  const type = CREDITCARD_TYPES.find(x => {
    const hasTypeMatched = payment.type.toLowerCase().includes(x)
    const hasDescMatched = payment.description.toLowerCase().includes(x)

    return hasTypeMatched || hasDescMatched
  })

  return CREDITCARD_IMGES[type ? type.toUpperCase() : 'GENERIC']
}
