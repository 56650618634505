import entries from 'lodash/entries'

import { getAppType } from 'utils/app'

const CATEGORIES = {
  CHECKOUT: 'Checkout',
  GLOBAL: 'Global',
  HOME: 'Home',
  SEARCH: 'Search',
  USER: 'User',
  VENUE: 'Venue',
}

export default entries({
  // User
  SIGNUP_CLICK_GOOGLE: {
    category: CATEGORIES.USER,
    action: 'Signup-Click-Google',
  },
  SIGNUP_CLICK_FACEBOOK: {
    category: CATEGORIES.USER,
    action: 'Signup-Click-Facebook',
  },
  SIGNUP_CLICK_EMAIL: {
    category: CATEGORIES.USER,
    action: 'Signup-Click-Email',
  },
  // Global
  HEADER_CLICK_LOGO: {
    category: CATEGORIES.GLOBAL,
    action: 'Header-Click-Logo',
  },
  HEADER_CLICK_BUSINESS: {
    category: CATEGORIES.GLOBAL,
    action: 'Header-Click-Business',
  },
  HEADER_CLICK_LOGIN: {
    category: CATEGORIES.GLOBAL,
    action: 'Header-Click-Login',
  },
  HEADER_CLICK_PROFILE: {
    category: CATEGORIES.GLOBAL,
    action: 'Header-Click-Profile',
  },
  HEADER_CLICK_REFERRAL: {
    category: CATEGORIES.GLOBAL,
    action: 'Header-Click-Referral',
  },
  HEADER_CLICK_FAVOURITES: {
    category: CATEGORIES.GLOBAL,
    action: 'Header-Click-Favourites',
  },
  HEADER_CLICK_PAYMENT: {
    category: CATEGORIES.GLOBAL,
    action: 'Header-Click-Payment',
  },
  HEADER_CLICK_HISTORY: {
    category: CATEGORIES.GLOBAL,
    action: 'Header-Click-History',
  },
  HEADER_CLICK_LOYALTY: {
    category: CATEGORIES.GLOBAL,
    action: 'Header-Click-Loyalty',
  },
  HEADER_CLICK_REDEEM_CODE: {
    category: CATEGORIES.GLOBAL,
    action: 'Header-Click-RedeemCode',
  },
  HERO_CLICK_ADDRESS_INPUT: {
    category: CATEGORIES.HOME,
    action: 'Hero-Click-AddressInput',
  },
  HERO_CLICK_CURRENT_LOCATION: {
    category: CATEGORIES.HOME,
    action: 'Hero-Click-CurrentLocation',
  },
  HERO_CLICK_LOCATION: {
    category: CATEGORIES.HOME,
    action: 'Hero-Click-Location',
  },

  HERO_CLICK_CITYTAB: {
    category: CATEGORIES.HOME,
    action: 'Hero-Click-CityTab',
  },
  POPULAR_CLICK_VIEWALL: {
    category: CATEGORIES.HOME,
    action: 'Popular-Click-ViewAll',
  },
  POPULAR_CLICK_VENUE: {
    category: CATEGORIES.HOME,
    action: 'Popular-Click-Venue',
  },
  POPULAR_CLICK_RIGHT: {
    category: CATEGORIES.HOME,
    action: 'Popular-Click-Right',
  },
  POPULAR_CLICK_LEFT: {
    category: CATEGORIES.HOME,
    action: 'Popular-Click-Left',
  },
  WHATS_HAPPENING_CLICK_VENUE: {
    category: CATEGORIES.HOME,
    action: 'WhatsHappening-Click-Venue',
  },
  WHATS_HAPPENING_CLICK_RIGHT: {
    category: CATEGORIES.HOME,
    action: 'WhatsHappening-Click-Right',
  },
  WHATS_HAPPENING_CLICK_LEFT: {
    category: CATEGORIES.HOME,
    action: 'WhatsHappening-Click-Left',
  },
  CUISINE_CLICK: {
    category: CATEGORIES.HOME,
    action: 'Cuisine-Click',
  },
  SUBHERO_CLICK_ADDRESS_INPUT: {
    category: CATEGORIES.HOME,
    action: 'SubHero-Click-AddressInput',
  },
  SUBHERO_CLICK_CURRENT_LOCATION: {
    category: CATEGORIES.HOME,
    action: 'SubHero-Click-CurrentLocation',
  },
  SUBHERO_CLICK_LOCATION: {
    category: CATEGORIES.HOME,
    action: 'SubHero-Click-Location',
  },
  APP_CLICK_ANDROID: {
    category: CATEGORIES.HOME,
    action: 'App-Click-Android',
  },
  APP_CLICK_IOS: {
    category: CATEGORIES.HOME,
    action: 'App-Click-iOS',
  },

  TESTIMONIAL_CLICK_RIGHT: {
    category: CATEGORIES.HOME,
    action: 'Testimonial-Click-Right',
  },

  TESTIMONIAL_CLICK_LEFT: {
    category: CATEGORIES.HOME,
    action: 'Testimonial-Click-Left',
  },
  // Venue
  RESULT_CLICK_VENUE: {
    category: CATEGORIES.VENUE,
    action: 'Result-Click-Venue',
  },
  RESULT_CLICK_LOADMORE: {
    category: CATEGORIES.VENUE,
    action: 'Result-Click-LoadMore',
  },
  MENU_CLICK_ITEM: {
    category: CATEGORIES.VENUE,
    action: 'Menu-Click-Item',
  },
  MENU_CLICK_ADD_TO_ORDER: {
    category: CATEGORIES.VENUE,
    action: 'Menu-Click-AddToOrder',
  },
  MENU_CLICK_GO_TO_CHECKOUT: {
    category: CATEGORIES.VENUE,
    action: 'Menu-Click-GoToCheckout',
  },
  // Checkout
  ORDER_CLICK_SUBMIT: {
    category: CATEGORIES.CHECKOUT,
    action: 'Order-Click-Submit',
  },
}).reduce(
  (prev, [key, value]) => ({
    ...prev,
    ...{
      [key]: {
        ...value,
        app_type: getAppType(),
      },
    },
  }),
  {},
)
