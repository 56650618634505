import { handleActions } from 'redux-actions'
import { createPromiseAction } from 'utils/asyncActions'
import * as homepage from './homepage'

export const fetchAds = createPromiseAction('unicorn/homescreen/ads/FETCH_ADS')

const initState = { }

export default handleActions(
  {
    [fetchAds]: state => ({ ...state }),
    [fetchAds.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
  initState,
)

export const getAds = state => homepage.getAds(state)
export const getHeroAds = state => homepage.getHeroAds(state)
