import { combineReducers } from 'redux'
import city from './city'
import ads from './ads'
import get from 'lodash/get'

export default combineReducers({ city, ads })

export const getCity = state => state.homepage.city
export const getAds = state => get(state,'homepage.ads.slides',[])
export const getHeroAds = state => get(state,'homepage.ads.heroAds.slides',[])
